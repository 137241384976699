import { getStyles, ResponsiveText } from '@eventbrite/wagtail-components';
import React, { useLayoutEffect, useRef } from 'react';
import { useTheme } from '../../../utils/context/theme-context';
import {
    WagtailModulePagesBreadCrumb,
    WagtailModulePagesBreadCrumbItem,
} from '../../../utils/WagtailModulePagesBreadCrumb';
import CTABlock, {
    CTABlockInterface,
} from '../../sharedComponents/cta-block/CTABlock';
import LazyloadImage from '../../sharedComponents/LazyloadImage';
import { BaseModuleProps } from '../types';
import { convertContentfulVariantToEnum, sanitizeHTML } from '../utils';
import './standardCallToAction.scss';

export interface ImageBlock {
    imageUrl: string;
    altText: string;
    alignment?: 'left' | 'right';
}

export interface StandardCallToActionProps {
    eyebrow?: string;
    headline?: string;
    bodyText: string;
    standardCtaGroup?: CTABlockInterface[];
    moduleClass?: string;
    subText?: string;
    image?: ImageBlock;
    alignment?: 'left' | 'right';
    breadcrumbs?: WagtailModulePagesBreadCrumbItem[];
    isContentFromContentful?: boolean;
}

const getAlignment = (alignment: string | undefined, content = false): any => {
    const contentAlignment = alignment === 'left' ? 'right' : 'left';
    return `aligned__${content ? contentAlignment : alignment}`;
};

const StandardCallToAction: React.FC<
    StandardCallToActionProps & BaseModuleProps
> = ({
    eyebrow,
    headline,
    bodyText,
    standardCtaGroup,
    image,
    alignment = 'left',
    subText,
    variant,
    breadcrumbs,
    isContentFromContentful,
}) => {
    // creating styles incase data source is contentful.
    let headingStyles;
    let imageAlignment = image?.alignment;
    const headingClassRichText = 'rich-text-heading';
    if (isContentFromContentful) {
        headingStyles = getStyles(
            {
                fontSize: 52,
                lineHeight: 64,
            },
            {
                small: {
                    fontSize: 32,
                    lineHeight: 40,
                },
            },
            headingClassRichText,
        );
        variant = convertContentfulVariantToEnum(variant);
        imageAlignment = alignment;
    }
    const baseOptions = { variant };
    const theme = useTheme();
    const [backgroundClass] = theme.generateStyles('background', baseOptions);
    const [textClass] = theme.generateStyles('text', baseOptions);

    // for headline we get richtext from contentful so need to access the rendered html to attach css class
    const parentTitleRef = useRef<HTMLDivElement>(null);
    useLayoutEffect(() => {
        if (parentTitleRef.current) {
            const parentElement = parentTitleRef.current;
            const children = Array.from(parentElement.children);

            children.forEach((child) => {
                child.classList.add(
                    textClass,
                    headingClassRichText,
                    'standard-cta-module__content__headline',
                );
            });
        }
    }, [isContentFromContentful, textClass]);

    return (
        <section className={`standard-cta-module ${backgroundClass}`}>
            <div className="module-landing-page__module-container-content">
                <div className="standard-cta-module__container">
                    {image && (
                        <div
                            className={`standard-cta-module__image-container ${getAlignment(
                                imageAlignment,
                            )}`}
                        >
                            <LazyloadImage
                                image={image.imageUrl}
                                alt={image.altText}
                            />
                        </div>
                    )}
                    <div
                        className={`standard-cta-module__content ${getAlignment(
                            imageAlignment,
                            true,
                        )}`}
                    >
                        <div className="standard-cta-module__content__container">
                            {breadcrumbs ? (
                                <WagtailModulePagesBreadCrumb
                                    containerClasses={`standard-cta-module__breadcrumbs ${textClass}`}
                                    breadcrumbs={breadcrumbs}
                                />
                            ) : null}
                            {!breadcrumbs && eyebrow ? (
                                <ResponsiveText
                                    base={{ fontSize: 14, lineHeight: 20 }}
                                    small={{ fontSize: 12, lineHeight: 16 }}
                                    className={`standard-cta-module__content__eyebrow ${textClass}`}
                                >
                                    {eyebrow}
                                </ResponsiveText>
                            ) : null}
                            {headline && (
                                <header>
                                    {isContentFromContentful ? (
                                        <>
                                            <style>{headingStyles}</style>
                                            <div
                                                ref={parentTitleRef}
                                                // eslint-disable-next-line react/no-danger
                                                dangerouslySetInnerHTML={{
                                                    __html: sanitizeHTML(
                                                        headline,
                                                    ),
                                                }}
                                            ></div>
                                        </>
                                    ) : (
                                        <ResponsiveText
                                            component="h2"
                                            base={{
                                                fontSize: 52,
                                                lineHeight: 64,
                                            }}
                                            small={{
                                                fontSize: 32,
                                                lineHeight: 40,
                                            }}
                                            className={`standard-cta-module__content__headline ${textClass}`}
                                        >
                                            {headline}
                                        </ResponsiveText>
                                    )}
                                </header>
                            )}
                            <ResponsiveText
                                component="main"
                                base={{ fontSize: 18, lineHeight: 24 }}
                                small={{ fontSize: 14, lineHeight: 20 }}
                                className={`standard-cta-module__content__bodyText ${textClass}`}
                            >
                                {bodyText}
                            </ResponsiveText>
                            {standardCtaGroup && (
                                <div className="standard-cta-module__content__cta-blocks">
                                    {standardCtaGroup.reduce<JSX.Element[]>(
                                        (ctaBlocks, blockProps, idx) => {
                                            if (blockProps.url) {
                                                // Only accumulate if blockProps.url exists
                                                ctaBlocks.push(
                                                    <CTABlock
                                                        key={idx}
                                                        {...{
                                                            ...blockProps,
                                                            size: 'regular',
                                                            variant: 'button',
                                                            secondary: idx > 0,
                                                            themeVariant:
                                                                variant,
                                                        }}
                                                    />,
                                                );
                                            }
                                            return ctaBlocks;
                                        },
                                        [],
                                    )}
                                </div>
                            )}
                            <div
                                className={`standard-cta-module__content__subtext ${textClass}`}
                            >
                                {subText}
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </section>
    );
};

export default StandardCallToAction;
